<template>

  <v-card flat class="mx-auto" max-width="1200">
    <v-expansion-panels flat v-model="panel" accordion>
      <v-expansion-panel v-for="(item,index) in info" :key="index">
        <v-expansion-panel-header>
          <v-row>
            <template v-for="(item1,index1) in item.personalityList">
              <v-col md="3" sm="3" cols="12" :key="index1" v-if="panel==index?true:index1<8">
                <v-sheet
                  height="90"
                  class="d-flex flex-column justify-center align-start pl-8"
                  color="#F0F5FF"
                  style="border:1px solid rgba(39,135,255,0.3);"
                >
                  <p
                    class="mb-0 subtitle-1"
                    style="font-size:18px;color:rgba(31,51,72,0.7);"
                  >{{item1.title==''?'未填写':item1.title}}</p>
                  <p
                    class="mb-0 mt-2 title"
                    style="font-size:18px;color:rgba(31,51,72,0.7);"
                  >{{item1.value==''?'未填写':item1.value}}</p>
                </v-sheet>
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 装卸前数据 -->
          <div class="title">
            <v-sheet
              class="white--text"
              tile
              color="#2787FF"
              width="160"
              style="border-radius:0px 4px 4px 0px;"
            >装卸前数据</v-sheet>
          </div>
          <v-container
            class="mt-8"
            style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
          >
            <v-row no-gutters class="title">
              <v-col class="d-flex justify-space-around align-center">
                <p class="mb-0">艏吃水</p>
                <p
                  style="color:rgba(31,51,72,0.4);"
                  class="mb-0"
                >{{item.workList.before.forntleft?item.workList.before.forntleft:'未填写'}}</p>
              </v-col>
              <v-col
                class="d-flex justify-space-around align-center"
                style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
              >
                <p class="mb-0">艉吃水</p>
                <p
                  class="mb-0"
                  style="color:rgba(31,51,72,0.4);"
                >{{item.workList.before.afterleft?item.workList.before.afterleft:'未填写'}}</p>
              </v-col>
              <v-col class="d-flex justify-space-around align-center">
                <p class="mb-0">实验室密度</p>
                <p
                  class="mb-0"
                  style="color:rgba(31,51,72,0.4);"
                >{{item.workList.before.qiandensity?item.workList.before.qiandensity:'未填写'}}</p>
              </v-col>
            </v-row>
          </v-container>
          <v-tabs
            v-model="item.qtab"
            class="mt-8"
            background-color="#F3F9FF"
            style="border:2px solid #A9CFFF;border-radius:7px;"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              v-for="(itme3,index3) in item.workList.after.data"
              :key="index3"
            >{{ itme3.cabinname }}</v-tab>
            <v-tabs-items v-model="item.qtab" style="border-top:2px solid #A9CFFF;">
              <v-tab-item
                v-for="(itme4,index4) in item.workList.after.data"
                :key="index4"
                style="border-radius:7px;"
              >
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>空高</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.ullage?itme4.ullage:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>实高</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.sounding?itme4.sounding:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>温度</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.temperature?itme4.temperature:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>纵倾修正值</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.listcorrection?itme4.listcorrection:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>修正后空距</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.correntkong?itme4.ulcorrentkonglage:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>容量</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.cabinweight?itme4.cabinweight:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>体积修正系数</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.volume?itme4.volume:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>膨胀修正系数</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.expand?itme4.expand:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>标准容量</v-list-item-content>
                        <v-list-item-content class="align-end">{{ itme4.standardcapacity?itme4.standardcapacity:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
          <!-- 装卸后数据 -->
          <div class="title mt-8">
            <v-sheet
              class="white--text"
              tile
              color="#2787FF"
              width="160"
              style="border-radius:0px 4px 4px 0px;"
            >装卸后数据</v-sheet>
          </div>
          <v-container
            class="mt-8"
            style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
          >
            <v-row no-gutters class="title">
              <v-col class="d-flex justify-space-around align-center">
                <p class="mb-0">艏吃水</p>
                <p
                  style="color:rgba(31,51,72,0.4);"
                  class="mb-0"
                >{{item.workList.after.forntleft?item.workList.after.forntleft:'未填写'}}</p>
              </v-col>
              <v-col
                class="d-flex justify-space-around align-center"
                style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
              >
                <p class="mb-0">艉吃水</p>
                <p
                  class="mb-0"
                  style="color:rgba(31,51,72,0.4);"
                >{{item.workList.after.afterleft?item.workList.after.afterleft:'未填写'}}</p>
              </v-col>
              <v-col class="d-flex justify-space-around align-center">
                <p class="mb-0">实验室密度</p>
                <p
                  class="mb-0"
                  style="color:rgba(31,51,72,0.4);"
                >{{item.workList.after.houdensity?item.workList.after.houdensity:'未填写'}}</p>
              </v-col>
            </v-row>
          </v-container>
          <v-tabs
            v-model="item.htab"
            class="mt-8"
            background-color="#F3F9FF"
            style="border:2px solid #A9CFFF;border-radius:7px;"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              v-for="(item5,index5) in item.workList.after.data"
              :key="index5"
            >{{ item5.cabinname }}</v-tab>
            <v-tabs-items v-model="item.htab" style="border-top:2px solid #A9CFFF;">
              <v-tab-item
                v-for="(item6,index6) in item.workList.after.data"
                :key="index6"
                style="border-radius:7px;"
              >
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>空高</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.ullage?item6.ullage:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>实高</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.sounding?item6.sounding:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>温度</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.temperature?item6.temperature:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>纵倾修正值</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.listcorrection?item6.listcorrection:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>修正后空距</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.correntkong?item6.correntkong:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>容量</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.cabinweight?item6.cabinweight:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>体积修正系数</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.volume?item6.volume:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>膨胀修正系数</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.expand?item6.expand:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col class="list-col" md="4" sm="6" cols="6">
                      <v-list-item>
                        <v-list-item-content>标准容量</v-list-item-content>
                        <v-list-item-content class="align-end">{{ item6.standardcapacity?item6.standardcapacity:'未填写' }}</v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: "jobDetails",
  props: {
    info: {
      type: Array
    }
  },
  data: () => ({
    tab: 0,
    tab1: 0,
    items: [
      { tab: "One", content: "Tab 1 Content" },
      { tab: "Two", content: "Tab 2 Content" },
      { tab: "Three", content: "Tab 3 Content" },
      { tab: "Four", content: "Tab 4 Content" },
      { tab: "Five", content: "Tab 5 Content" },
      { tab: "Six", content: "Tab 6 Content" },
      { tab: "Seven", content: "Tab 7 Content" },
      { tab: "Eight", content: "Tab 8 Content" },
      { tab: "Nine", content: "Tab 9 Content" },
      { tab: "Ten", content: "Tab 10 Content" }
    ],
    panel: []
  }),
  created() {
    
  },
  methods: {
  }
};
</script>

<style lang="less">
@media screen {
  @media (min-width: 768px) {
    .list-col:nth-child(even) {
      border-right: 2px solid #a9cfff;
      border-bottom: 2px solid #a9cfff;
    }

    .list-col:nth-child(odd) {
      border-bottom: 2px solid #a9cfff;
    }
  }
  @media (min-width: 1200px) {
    .list-col {
      border-right: 2px solid #a9cfff;
      border-bottom: 2px solid #a9cfff;
    }
    .list-col:nth-child(3),
    .list-col:nth-child(6),
    .list-col:nth-child(9) {
      border-right: none;
    }
    .list-col:nth-child(7),
    .list-col:nth-child(8),
    .list-col:nth-child(9) {
      border-bottom: none;
    }
  }
}
</style>
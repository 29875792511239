<template>

  <v-card flat class="mx-auto" max-width="1200">
    <v-expansion-panels flat v-model="panel" accordion>
      <v-expansion-panel v-for="(item,index) in info" :key="index">
        <v-expansion-panel-header>
          <v-row>
            <template v-for="(item1,index1) in item.personalityList">
              <v-col md="3" sm="3" cols="12" :key="index1" v-if="panel==index?true:index1<8">
                <v-sheet
                  height="90"
                  class="d-flex flex-column justify-center align-start pl-8"
                  color="#F0F5FF"
                  style="border:1px solid rgba(39,135,255,0.3);"
                >
                  <p
                    class="mb-0 subtitle-1"
                    style="font-size:18px;color:rgba(31,51,72,0.7);"
                  >{{item1.title==''?'未填写':item1.title}}</p>
                  <p
                    class="mb-0 mt-2 title"
                    style="font-size:18px;color:rgba(31,51,72,0.7);"
                  >{{item1.value==''?'未填写':item1.value}}</p>
                </v-sheet>
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 首检水尺数据 -->
          <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
            <div class="title">
              <v-sheet
                class="white--text"
                tile
                color="#2787FF"
                width="160"
                style="border-radius:0px 4px 4px 0px;"
              >首检水尺数据</v-sheet>
            </div>
            <v-container
              class="mt-8"
              style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
            >
              <v-row no-gutters class="title">
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艏左吃水</p>
                  <p
                    style="color:rgba(31,51,72,0.4);"
                    class="mb-0"
                  >{{item.fornt.forntleft?item.fornt.forntleft:'未填写'}}</p>
                </v-col>
                <v-col
                  class="d-flex justify-space-around align-center"
                  style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
                >
                  <p class="mb-0">舯左吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt.centerleft?item.fornt.centerleft:'未填写'}}</p>
                </v-col>
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艉左吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt.afterleft?item.fornt.afterleft:'未填写'}}</p>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              class="mt-8"
              style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
            >
              <v-row no-gutters class="title">
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艏右吃水</p>
                  <p
                    style="color:rgba(31,51,72,0.4);"
                    class="mb-0"
                  >{{item.fornt.forntright?item.fornt.forntright:'未填写'}}</p>
                </v-col>
                <v-col
                  class="d-flex justify-space-around align-center"
                  style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
                >
                  <p class="mb-0">舯右吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt.centerright?item.fornt.centerright:'未填写'}}</p>
                </v-col>
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艉右吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt.afterright?item.fornt.afterright:'未填写'}}</p>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              class="mt-8"
              style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
            >
              <v-row no-gutters class="title">
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艏平均吃水</p>
                  <p
                    style="color:rgba(31,51,72,0.4);"
                    class="mb-0"
                  >{{item.fornt.fm?item.fornt.fm:'未填写'}}</p>
                </v-col>
                <v-col
                  class="d-flex justify-space-around align-center"
                  style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
                >
                  <p class="mb-0">舯平均吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt.mm?item.fornt.mm:'未填写'}}</p>
                </v-col>
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艉平均吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt.am?item.fornt.am:'未填写'}}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <!-- 末检水尺数据 -->
          <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
            <div class="title mt-8">
              <v-sheet
                class="white--text"
                tile
                color="#2787FF"
                width="160"
                style="border-radius:0px 4px 4px 0px;"
              >末检水尺数据</v-sheet>
            </div>
            <v-container
              class="mt-8"
              style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
            >
              <v-row no-gutters class="title">
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艏左吃水</p>
                  <p
                    style="color:rgba(31,51,72,0.4);"
                    class="mb-0"
                  >{{item.fornt1.forntleft?item.fornt1.forntleft:'未填写'}}</p>
                </v-col>
                <v-col
                  class="d-flex justify-space-around align-center"
                  style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
                >
                  <p class="mb-0">舯左吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt1.centerleft?item.fornt1.centerleft:'未填写'}}</p>
                </v-col>
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艉左吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt1.afterleft?item.fornt1.afterleft:'未填写'}}</p>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              class="mt-8"
              style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
            >
              <v-row no-gutters class="title">
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艏右吃水</p>
                  <p
                    style="color:rgba(31,51,72,0.4);"
                    class="mb-0"
                  >{{item.fornt1.forntright?item.fornt1.forntright:'未填写'}}</p>
                </v-col>
                <v-col
                  class="d-flex justify-space-around align-center"
                  style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
                >
                  <p class="mb-0">舯右吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt1.centerright?item.fornt1.centerright:'未填写'}}</p>
                </v-col>
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艉右吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt1.afterright?item.fornt1.afterright:'未填写'}}</p>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              class="mt-8"
              style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
            >
              <v-row no-gutters class="title">
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艏平均吃水</p>
                  <p
                    style="color:rgba(31,51,72,0.4);"
                    class="mb-0"
                  >{{item.fornt1.fm?item.fornt1.fm:'未填写'}}</p>
                </v-col>
                <v-col
                  class="d-flex justify-space-around align-center"
                  style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
                >
                  <p class="mb-0">舯平均吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt1.mm?item.fornt1.mm:'未填写'}}</p>
                </v-col>
                <v-col class="d-flex justify-space-around align-center">
                  <p class="mb-0">艉平均吃水</p>
                  <p
                    class="mb-0"
                    style="color:rgba(31,51,72,0.4);"
                  >{{item.fornt1.am?item.fornt1.am:'未填写'}}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          
          <v-row class="mx-auto" style="max-width:1200px">
            <!-- 首检排水量表 -->
            <v-col cols="12" sm="6" style="padding-left:0;">
              <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
                <div class="title mt-8">
                  <v-sheet
                    class="white--text"
                    tile
                    color="#2787FF"
                    width="160"
                    style="border-radius:0px 4px 4px 0px;"
                  >首检排水量表 D_M：{{item.content.qian_d_m?item.content.qian_d_m:'未填写'}}</v-sheet>
                </div>
                <table>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位吃水值</p>
                      <p class="shuju">{{item.table.d_up?item.table.d_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位吃水值</p>
                      <p class="shuju">{{item.table.d_down?item.table.d_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位TPC值</p>
                      <p class="shuju">{{item.table.tpc_up?item.table.tpc_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位TPC值</p>
                      <p class="shuju">{{item.table.tpc_down?item.table.tpc_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位DS值</p>
                      <p class="shuju">{{item.table.ds_up?item.table.ds_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位DS值</p>
                      <p class="shuju">{{item.table.ds_down?item.table.ds_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位LCA值</p>
                      <p class="shuju">{{item.table.lca_up?item.table.lca_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位LCA值</p>
                      <p class="shuju">{{item.table.lca_down?item.table.lca_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">计算后上位xf值</p>
                      <p class="shuju">{{item.table.xf_up?item.table.xf_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">计算后下位xf值</p>
                      <p class="shuju">{{item.table.xf_down?item.table.xf_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">D_M+50cm的mtc值</p>
                      <p class="shuju">{{item.table.mtc_up?item.table.mtc_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">D_M-50cm的mtc值</p>
                      <p class="shuju">{{item.table.mtc_down?item.table.mtc_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">当前港水密度</p>
                      <p class="shuju">{{item.content.qian_pwd?item.content.qian_pwd:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">表载港水密度</p>
                      <p class="shuju">{{item.table.ptwd?item.table.ptwd:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">矫正后最终排水量</p>
                      <p class="shuju">{{item.content.qian_dspc?item.content.qian_dspc:'未填写'}}</p>
                    </td>
                    <td class="bian"></td>
                  </tr>
                </table>
              </v-card>
            </v-col>

            <!-- 末检排水量表 -->
            <v-col cols="12" sm="6" style="padding-left:0;">
              <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
                <div class="title mt-8">
                  <v-sheet
                    class="white--text"
                    tile
                    color="#2787FF"
                    width="160"
                    style="border-radius:0px 4px 4px 0px;"
                  >首检排水量表 D_M：{{item.content.hou_d_m?item.content.hou_d_m:'未填写'}}</v-sheet>
                </div>
                <table>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位吃水值</p>
                      <p class="shuju">{{item.table1.d_up?item.table1.d_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位吃水值</p>
                      <p class="shuju">{{item.table1.d_down?item.table1.d_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位TPC值</p>
                      <p class="shuju">{{item.table1.tpc_up?item.table1.tpc_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位TPC值</p>
                      <p class="shuju">{{item.table1.tpc_down?item.table1.tpc_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位DS值</p>
                      <p class="shuju">{{item.table1.ds_up?item.table1.ds_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位DS值</p>
                      <p class="shuju">{{item.table1.ds_down?item.table1.ds_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">上位LCA值</p>
                      <p class="shuju">{{item.table1.lca_up?item.table1.lca_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">下位LCA值</p>
                      <p class="shuju">{{item.table1.lca_down?item.table1.lca_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">计算后上位xf值</p>
                      <p class="shuju">{{item.table1.xf_up?item.table1.xf_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">计算后下位xf值</p>
                      <p class="shuju">{{item.table1.xf_down?item.table1.xf_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">D_M+50cm的mtc值</p>
                      <p class="shuju">{{item.table1.mtc_up?item.table1.mtc_up:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">D_M-50cm的mtc值</p>
                      <p class="shuju">{{item.table1.mtc_down?item.table1.mtc_down:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">当前港水密度</p>
                      <p class="shuju">{{item.content.hou_pwd?item.content.hou_pwd:'未填写'}}</p>
                    </td>
                    <td class="bian">
                      <p class="tishi">表载港水密度</p>
                      <p class="shuju">{{item.table1.ptwd?item.table1.ptwd:'未填写'}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="bian">
                      <p class="tishi">矫正后最终排水量</p>
                      <p class="shuju">{{item.content.hou_dspc?item.content.hou_dspc:'未填写'}}</p>
                    </td>
                    <td class="bian"></td>
                  </tr>
                </table>
              </v-card>
            </v-col>
          </v-row>
          
          <v-row class="mx-auto" style="max-width:1200px">
            <!-- 首检压载水表 -->
            <v-col cols="12" sm="6" style="padding-left:0;">
              <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
                <div class="title mt-8">
                  <v-sheet
                    class="white--text"
                    tile
                    color="#2787FF"
                    width="160"
                    style="border-radius:0px 4px 4px 0px;"
                  >首检压载水表</v-sheet>
                </div>
              </v-card>
            </v-col>

            <!-- 末检压载水表 -->
            <v-col cols="12" sm="6" style="padding-left:0;">
              <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
                <div class="title mt-8">
                  <v-sheet
                    class="white--text"
                    tile
                    color="#2787FF"
                    width="160"
                    style="border-radius:0px 4px 4px 0px;"
                  >末检压载水表</v-sheet>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: "jobDetails",
  props: {
    info: {
      type: Array
    }
  },
  data: () => ({
    panel: []
  }),
  created() {
    
  },
  methods: {
  }
};
</script>

<style lang="less">
@media screen {
  @media (min-width: 768px) {
    .list-col:nth-child(even) {
      border-right: 2px solid #a9cfff;
      border-bottom: 2px solid #a9cfff;
    }

    .list-col:nth-child(odd) {
      border-bottom: 2px solid #a9cfff;
    }
  }
  @media (min-width: 1200px) {
    .list-col {
      border-right: 2px solid #a9cfff;
      border-bottom: 2px solid #a9cfff;
    }
    .list-col:nth-child(3),
    .list-col:nth-child(6),
    .list-col:nth-child(9) {
      border-right: none;
    }
    .list-col:nth-child(7),
    .list-col:nth-child(8),
    .list-col:nth-child(9) {
      border-bottom: none;
    }
  }
}
  .show-img{
    width:32px;
    height:32px;
    margin: 0 auto;
  }

  .theme--light.v-list {
    margin-bottom: 15px;
    background: rgb(39, 135, 255);
    color: rgb(255 255 255 / 87%);
    border-radius:0px 3px 3px 0px;
    font-size: 10px;
    // width: 100%;
  }

  .list-text{
    width: 72px;
    height: 20px;
    font-size: 10px;
    color: #fff;
  }

  .fornt-col{
    border-top: solid 1px RGBA(183, 207, 242, 1);
    border-bottom: solid 1px RGBA(183, 207, 242, 1);
    padding-left: 60px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fornt-left-span{
    float: left;
  }

  .fornt-right-span{
    float: right;
  }

  .dayingbtn{
    margin: 30px 0 30px 0;
    width: 200px;
    height: 30px;
    background: rgba(39,135,255,1);
    border-radius: 3px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }

  .bian{
    border: 1px solid rgba(39,135,255,0.4);
    height: 85px;
    text-align: left;
    width: 264px;
    padding-left: 20px;
  }

  .shuju{
    font-size:18px;
    font-weight:400;
    color:rgba(155,163,172,1);
    line-height:40px;
  }

  .tishi{
    font-size:20px;
    font-weight:400;
    color:rgba(28,48,68,1);
    line-height:40px;
  }

  .v-application p {
    // margin-left: 20px;
    line-height: 25px;
    margin-bottom: 0px !important;
  }

  table {
    border-collapse: separate;
    border-spacing: 0px !important;
  }
</style>
<template>
  <div>
    <v-card min-height="900" color="#f9f9ff" style="padding:100px 0;">
      <div style="width:1200px" class="mx-auto">
        <v-breadcrumbs :items="routItems" class="px-0"></v-breadcrumbs>
      </div>
      <v-card tile flat max-width="1200" class="mx-auto mt-6 px-4 pb-6">
        <jobDetails :info="info" v-if="this.tab=='2'"></jobDetails>
        <jobShanDetails :info="info" v-if="this.tab=='3'"></jobShanDetails>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import jobDetails from "@/components/common/jobDetails";
import jobShanDetails from "@/components/common/jobShanDetails";
import qs from "qs";
export default {
  components: {
    jobDetails: jobDetails,
    jobShanDetails: jobShanDetails
  },
  data: () => ({
    info: [],
    routItems: [
      {
        text: "船舶管理",
        disabled: false,
        to: "Inquire"
      },
      {
        text: "船舶详情",
        disabled: false,
        to: "ShipDetails"
      },
      {
        text: "作业数据详情",
        disabled: true,
        to: "ShipJobDetails"
      }
    ],
    tab: '',
    id: ''
  }),
  created() {
    this.getInfo();
  },
  mounted() {},
  methods: {
    getInfo() {
      // 船舶类型 2:液货船 3:散货船
      let tab = this.$route.query.tab;
      this.tab = tab;
      // 船舶id
      let id = this.$route.query.id;
      this.id = id;
      if (tab == 2) {
        this.$server.getYeWorkId({ shipid: id }).then(data => {
          for (const i in data.list) {
            this.info.push({
              qtab: 0,
              htab: 0,
              workList: [],
              personalityList: []
            });
            this.info[i].workList = {
              before: {
                forntleft: data.list[i].qianchi.forntleft,
                afterleft: data.list[i].qianchi.afterleft,
                qiandensity: data.list[i].qiandensity,
                data: []
              },
              after: {
                forntleft: data.list[i].houchi.forntleft,
                afterleft: data.list[i].qianchi.afterleft,
                houdensity: data.list[i].houdensity,
                data: []
              }
            };
            for (const j in data.list[i].qian) {
              this.info[i].workList.before.data.push(data.list[i].qian[j]);
              this.info[i].workList.after.data.push(data.list[i].hou[j]);
            }
            this.info[i].personalityList = data.list[i].personality;
          }
        });
      }else if (tab == 3){
        this.$server.getSanWorkId({ shipid: id }).then(data => {
          for (let i in data.list) {
            this.info.push({
              content: data.list[i],
              personalityList: data.list[i].personality_array?data.list[i].personality_array:{},
              fornt: data.list[i].qian.fornt?data.list[i].qian.fornt:{},
              table: data.list[i].qian.table?data.list[i].qian.table:{},
              fornt1: data.list[i].hou.fornt?data.list[i].hou.fornt:{},
              table1: data.list[i].hou.table?data.list[i].hou.table:{},
            });
          }
        });
      }
    }
  }
};
</script>

<style>
</style>